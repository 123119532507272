import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/Casstor',
    name: 'Casstor',
    component: () => import(/* webpackChunkName: "about" */ '../views/CasstorMainView.vue')
  },
  {
    path: '/Casstor/Alcance',
    name: 'Alcance',
    component: () => import(/* webpackChunkName: "about" */ '../views/CasstorScopeView.vue')
  },
  {
    path: '/Casstor/Suscripcion',
    name: 'Suscripcion',
    component: () => import(/* webpackChunkName: "about" */ '../views/CasstorSubscriptionView.vue')
  },
  {
    path: '/Casstor/Diferencias',
    name: 'Diferencias',
    component: () => import(/* webpackChunkName: "about" */ '../views/CasstorDifferencesView.vue')
  },
  {
    path: '/Casstor/Historia',
    name: 'Historia',
    component: () => import(/* webpackChunkName: "about" */ '../views/CasstorHistoryView.vue')
  },
  {
    path: '/Experiencia',
    name: 'Experiencia',
    component: () => import(/* webpackChunkName: "about" */ '../views/ExperienceView.vue')
  },
  {
    path: '/FAQs',
    name: 'FAQs',
    component: () => import(/* webpackChunkName: "about" */ '../views/FAQsView.vue')
  },
  {
    path: '/Contacto',
    name: 'Contacto',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue')
  }



]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }

})



export default router
