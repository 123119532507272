<template>
  <div>
    <div class="carousel relative shadow-xl bg-white">
      <div class="carousel-inner relative overflow-hidden w-full">
        <!--Slide 1-->
        <input class="carousel-open" type="radio" id="carousel-1" name="carousel" aria-hidden="true" hidden="" checked="checked" />
        <div class="carousel-item absolute opacity-0">
          <div class="bg-[url(@/assets/home1-transformacion-digital-3.png)] sm:bg-[url(@/assets/home1-transformacion-digital.png)] bg-left 2xl:bg-center bg-cover py-28 sm:py-36 xl:py-40 2xl:py-60 1k:py-72">
            <a href="/Contacto" class="rounded-3xl absolute bottom-10 2xl:bottom-32 right-20 2xl:right-32">
              <button class="hidden lg:block bg-white hover:bg-light-grey-epica text-blue-epica text-sm 2xl:text-lg py-3 2xl:py-7 w-56 2xl:w-64 font-normal rounded-full">CONTÁCTENOS <i class="fa-solid fa-arrow-right-long"> </i></button>
            </a>
          </div>
        </div>
        <label
          for="carousel-3"
          class="prev control-1 w-0 lg:w-10 lg:h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-[0px] lg:text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-epica leading-tight text-center z-10 inset-y-0 left-0 my-auto"
          >‹</label
        >
        <label
          for="carousel-2"
          class="next control-1 w-0 lg:w-10 lg:h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-[0px] lg:text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-epica leading-tight text-center z-10 inset-y-0 right-0 my-auto"
          >›</label
        >

        <!--Slide 2-->
        <input class="carousel-open" type="radio" id="carousel-2" name="carousel" aria-hidden="true" hidden="" />
        <div class="carousel-item absolute opacity-0">
          <div class="bg-[url(@/assets/home2-asesoria-3.png)] sm:bg-[url(@/assets/home2-asesoria.png)] bg-left 2xl:bg-center bg-cover py-28 sm:py-36 xl:py-40 2xl:py-60 1k:py-72"></div>
        </div>
        <label
          for="carousel-1"
          class="prev control-2 w-0 lg:w-10 lg:h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-[0px] lg:text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-epica leading-tight text-center z-10 inset-y-0 left-0 my-auto"
          >‹</label
        >
        <label
          for="carousel-3"
          class="next control-2 w-0 lg:w-10 lg:h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-[0px] lg:text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-epica leading-tight text-center z-10 inset-y-0 right-0 my-auto"
          >›</label
        >

        <!--Slide 3-->
        <input class="carousel-open" type="radio" id="carousel-3" name="carousel" aria-hidden="true" hidden="" />
        <div class="carousel-item absolute opacity-0">
          <div class="bg-[url(@/assets/home3-sistema-seguridad-3.png)] sm:bg-[url(@/assets/home3-sistema-seguridad.png)] bg-left 2xl:bg-center bg-cover py-28 sm:py-36 xl:py-40 2xl:py-60 1k:py-72"></div>
        </div>
        <label
          for="carousel-2"
          class="prev control-3 w-0 lg:w-10 lg:h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-[0px] lg:text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-epica leading-tight text-center z-10 inset-y-0 left-0 my-auto"
          >‹</label
        >
        <label
          for="carousel-1"
          class="next control-3 w-0 lg:w-10 lg:h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-[0px] lg:text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-epica leading-tight text-center z-10 inset-y-0 right-0 my-auto"
          >›</label
        >

        <!-- Add additional indicators for each slide-->
        <ol class="carousel-indicators">
          <li class="inline-block mr-3">
            <label for="carousel-1" class="carousel-bullet cursor-pointer block text-4xl xl:text-6xl text-white hover:text-light-skyblue">•</label>
          </li>
          <li class="inline-block mr-3">
            <label for="carousel-2" class="carousel-bullet cursor-pointer block text-4xl xl:text-6xl text-white hover:text-light-skyblue">•</label>
          </li>
          <li class="inline-block mr-3">
            <label for="carousel-3" class="carousel-bullet cursor-pointer block text-4xl xl:text-6xl text-white hover:text-light-skyblue">•</label>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}
/* .carousel-item {
  -webkit-transition: opacity 1s ease-out;
  transition: opacity 1s ease-out;
} */

#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
  display: block;
}
.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}
#carousel-1:checked ~ .control-1 ~ .carousel-indicators li:nth-child(1) .carousel-bullet,
#carousel-2:checked ~ .control-2 ~ .carousel-indicators li:nth-child(2) .carousel-bullet,
#carousel-3:checked ~ .control-3 ~ .carousel-indicators li:nth-child(3) .carousel-bullet {
  color: #50bff5; /*Set to match the Tailwind colour you want the active one to be */
}
</style>
