<template>
  <footer class="bg-gray-footer p-9 text-white">
    <!-- container mx-auto -->
    <!-- <section class="flex justify-around flex-wrap items-center"> -->
    <section class="grid grid-col-1 md:grid-cols-3 gap-y-5">
      <div class="flex justify-center items-center">
        <a href="/"><img class="h-24 sm:h-28 object-fill" src="../assets/epica-logo-white.png" alt="logo epica consultores" /></a>
      </div>

      <div class="font-light text-xs lg:text-sm">
        <p>Carrera 23 No 62 39 OF 602</p>
        <a class="flex justify-center items-center" href="tel:+573206764879">+57 320 676 4879<img src="@\assets\phone.png" class="w-4 h-4 ml-3" /></a>
        <!-- AGREGAR BOTON LLAMAR  -->
        <p><a href="mailto:contacto@epicaconsultores.com.co ">contacto@epicaconsultores.com.co </a></p>
        <p>Manizales (Caldas), Colombia</p>
        <br />
        <br />
        <p>Copyright © 2022,</p>
        <p>EPICA CONSULTORES S.A.S.</p>
        <p>Todos los derechos reservados</p>
      </div>

      <div class="pt-6">
        <div class="flex justify-center pt-4 h-full">
          <div class="space-y-2">
            <h1 class="font-bold">Social Media</h1>
            <div>
              <a href="https://www.linkedin.com/company/79480081/admin/" target="_blank" rel="noopener noreferrer" class="hover:text-gray-100">
                <i class="fa-brands fa-linkedin fa-lg text-3xl"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="flex flex-col mx-auto md:flex-row space-y-3 md:space-y-0 md:space-x-5 place-content-center place-items-center mt-10">
      <h1 class="text-gray-300 text-lg font-normal">With Love:</h1>
      <a class="flex justify-center" href="https://softgreen.com.co/" target="_blank" rel="noopener noreferrer">
        <img class="h-12" src="@/assets/softgreen.png" alt="Logo Softgreen" />
      </a>
      <a class="flex justify-center" href="https://cide.org.co/" target="_blank" rel="noopener noreferrer">
        <img class="h-12" src="@/assets/cide.png" alt="Logo CIDE" />
      </a>
      <a class="flex justify-center" href="https://registra.com.co/" target="_blank" rel="noopener noreferrer">
        <img class="h-12" src="@/assets/registra.png" alt="Logo Registra" />
      </a>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.border-footer {
  border-top-width: 1px;
  border-color: rgb(188, 184, 184);
}
</style>
