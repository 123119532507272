<template>
  <NavPage />
  <Carousel />

  <section>
    <div class="text-xs sm:text-xl lg:text-2xl p-6 lg:px-24 xl:px-40 lg:py-14 bg-light-skyblue text-white">
      <p class="leading-tight xl:leading-snug font-light">”El bienestar de tu empresa y colaboradores es nuestra promesa de valor, previniendo, protegiendo de las lesiones-enfermedades y cumpliendo con la normatividad.”</p>
    </div>
  </section>

  <section>
    <div class="bg-[url(@/assets/nosotros-epica.png)] bg-left xl:bg-left bg-cover py-20 sm:py-32 xl:py-40 2xl:py-60 1k:py-72"></div>
  </section>

  <section>
    <div class="text-xs sm:text-xl lg:text-2xl p-6 lg:px-24 xl:px-28 lg:py-14 bg-light-skyblue text-white">
      <p class="leading-tight xl:leading-snug font-light">
        EPICA CONSULTORES, es una empresa producto de la unión de dos (2) compañías especialistas en proyectos de implementación de sistemas de información y desarrollo de software, la cual adopta sus experiencias de más de 15 años en aplicaciones de
        talento humano, seguridad y salud en el trabajo, y desarrollo de soluciones, para proyectos en empresas en diferentes sectores de la economía.
      </p>
    </div>
  </section>

  <FooterPage />
</template>

<script>
import NavPage from "@/components/NavPage.vue";
import FooterPage from "@/components/FooterPage.vue";
import Carousel from "@/components/Carousel.vue";

export default {
  name: "HomeView",
  components: {
    NavPage,
    FooterPage,
    Carousel,
  },
};
</script>
