<template>
  <router-view />
</template>

<script></script>

<style>
@font-face {
    font-family: 'archiaregular';
    src: url('@/assets/fonts/Archia/archia-regular-webfont.eot');
    src: url('@/assets/fonts/Archia/archia-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('@/assets/fonts/Archia/archia-regular-webfont.woff2') format('woff2'),
         url('@/assets/fonts/Archia/archia-regular-webfont.woff') format('woff'),
         url('@/assets/fonts/Archia/archia-regular-webfont.ttf') format('truetype');
         
    /* font-weight: normal;
    font-style: normal; */
}

#app {
  font-family: "archiaregular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
