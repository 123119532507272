<template>
  <nav class="sticky top-0 z-50 bg-white flex items-center justify-around flex-wrap p-0 border-b-2 border-light-grey-epica">
    <div class="flex items-center flex-shrink-0 text-white">
      <a href="/"><img class="w-44 object-fill" src="../assets/epica-logo.png" alt="logo epica consultores" /></a>
    </div>

    <div class="block lg:hidden">
      <button id="burguer-button" @click="myFunctionClick()" class="flex items-center px-3 py-2 shadow-lg border-2 rounded text-blue-epica border-blue-epica active:text-blue-epica active:border-blue-epica">
        <svg class="fill-current h-5 w-6" viewBox="0 0 20 20">
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </button>
    </div>

    <div id="menu-list" class="w-full hidden lg:flex lg:w-auto py-2">
      <ul class="text-md 2k:text-4xl flex-col space-y-2 lg:space-y-0 lg:flex-grow lg:flex-row flex lg:space-x-10 mt-5 lg:mt-0">
        <li>
          <router-link to="/" class="font-normal">HOME</router-link>
        </li>

        <li>
          <router-link to="/Casstor" class="font-normal">CA<span class="font-black">SST</span>OR</router-link>
        </li>

        <li>
          <router-link to="/Experiencia" class="font-normal">EXPERIENCIA</router-link>
        </li>

        <li>
          <router-link to="/FAQs" class="font-normal">FAQ'S</router-link>
        </li>

        <li>
          <router-link to="/Contacto" class="font-normal">CONTACTO</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script scoped>
export default {
  methods: {
    myFunctionClick: function () {
      const menu = document.querySelector("#menu-list");
      if (menu) {
        menu.classList.toggle("hidden");
      }
    },
  },
};
</script>

<style scoped>
nav a.router-link-exact-active {
  color: #000000;
  border-top-width: 2px;
  border-bottom-width: 2px;
  padding-top: 2px;
  border-color: rgb(0 0 0);
}

</style>
